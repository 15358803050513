// import '../scss/style.scss';

import hljs from 'highlight.js';
import '../scss/colors.scss';
hljs.highlightAll();

// #2blue Ищем на странице все <div> элементы с классом code-toolbar и добавляем туда кнопку копировать содержимое.
// //#1blue Находим все div элементы с классом code-toolbar  — для Локальной сборки
// import copyOnImage from '../media/img/copy_on.svg';
// import copyOffImage from '../media/img/copy_off.svg';
// let codeToolbars = document.getElementsByClassName('wp-block-code');

// // Перебираем найденные элементы и добавляем указанный код
// for (let i = 0; i < codeToolbars.length; i++) {
//   let codeToolbar = codeToolbars[i];

//   // Создаем элемент div с классом copy-btn
//   let copyBtnDiv = document.createElement('div');
//   copyBtnDiv.className = 'copy-btn';

//   // Создаем элемент img с классом copy-off и задаем атрибуты src и alt
//   let copyOffImg = document.createElement('img');
//   copyOffImg.className = 'copy-off';
//   copyOffImg.src = copyOffImage;
//   copyOffImg.alt = 'Copy';

//   // Создаем элемент img с классом copy-on и задаем атрибуты src и alt
//   let copyOnImg = document.createElement('img');
//   copyOnImg.className = 'copy-on';
//   copyOnImg.src = copyOnImage;
//   copyOnImg.alt = 'Copied';

//   // Добавляем элементы img внутрь элемента div с классом copy-btn
//   copyBtnDiv.appendChild(copyOffImg);
//   copyBtnDiv.appendChild(copyOnImg);

//   // Добавляем элемент div с классом copy-btn внутрь div с классом code-toolbar
//   codeToolbar.appendChild(copyBtnDiv);
// }

//#1blue Подключаем из файла functions.php файлы. — для WordPress
// Функция создает и возвращает элемент изображения (img) с заданными классом, источником и альтернативным текстом
function createImage(className, src, alt) {
  const img = document.createElement('img'); // Создание нового элемента img
  img.className = className; // Установка класса элемента
  img.src = src; // Установка источника изображения
  img.alt = alt; // Установка альтернативного текста изображения
  return img; // Возврат созданного элемента img
}

// Основная функция для добавления кнопок копирования к блокам кода на странице
// Принимает пути к изображениям для кнопок "копировать" и "скопировано"
function addCopyButtons(copyOnImage, copyOffImage) {
  const codeToolbars = document.querySelectorAll('.wp-block-code'); // Получение всех элементов с классом '.wp-block-code'
  const toolbarLength = codeToolbars.length; // Кэширование количества элементов для оптимизации производительности

  for (let i = 0; i < toolbarLength; i++) {
    const codeToolbar = codeToolbars[i]; // Получение текущего элемента блока кода
    const copyBtnDiv = document.createElement('div'); // Создание контейнера для кнопок копирования
    copyBtnDiv.className = 'copy-btn'; // Присвоение класса контейнеру

    // Создание и добавление изображений для кнопок "копировать" и "скопировано" с использованием функции createImage
    const copyOffImg = createImage('copy-off', copyOffImage, 'Copy');
    const copyOnImg = createImage('copy-on', copyOnImage, 'Copied');

    // Добавление кнопок в контейнер
    copyBtnDiv.appendChild(copyOffImg);
    copyBtnDiv.appendChild(copyOnImg);

    // Добавление контейнера кнопок в текущий блок кода
    codeToolbar.appendChild(copyBtnDiv);
  }
}

// Проверка наличия необходимых данных в custom_data и вызов функции addCopyButtons
// Это предотвращает вызов функции, если данные изображений не предоставлены
if (typeof custom_data !== 'undefined' && custom_data.copyOnImage && custom_data.copyOffImage) {
  // Вызов функции с параметрами из custom_data
  addCopyButtons(custom_data.copyOnImage, custom_data.copyOffImage);
}

// #2blue Следующий JavaScript-код добавляет обработчики событий для каждой кнопки копирования.
// Получаем все кнопки копирования
const copyBtns = document.querySelectorAll('.copy-btn');

// Для каждой кнопки
copyBtns.forEach(btn => {
  let timeoutId; // Для управления таймером

  btn.addEventListener('click', async () => {
    const codeBlock = btn.parentElement;
    const code = codeBlock.querySelector('code').textContent;

    try {
      await navigator.clipboard.writeText(code); // Копируем текст асинхронно

      const copyOff = btn.querySelector('.copy-off');
      const copyOn = btn.querySelector('.copy-on');

      // Показываем иконку успешного копирования
      copyOff.style.display = 'none';
      copyOn.style.display = 'block';

      // Отменяем предыдущий таймер, если он существует
      if (timeoutId) clearTimeout(timeoutId);

      // Сбрасываем иконку копирования через 5 секунд
      timeoutId = setTimeout(() => {
        copyOff.style.display = 'block';
        copyOn.style.display = 'none';
      }, 5000);
    } catch (error) {
      console.error('Ошибка при копировании в буфер обмена', error);
    }
  });

  // Пример удаления обработчика событий, если элемент удаляется
  // btn.removeEventListener('click', обработчик); // Необходимо иметь ссылку на функцию-обработчик
});

//#2blue Фон сайта в виде меняющихся бинарных цифр
const animationConfig = {
  numberChangeTime: 2000, // Интервал времени в миллисекундах для обновления чисел
  percentageOfChanges: 0.1, // Процент символов для случайного изменения
  numbersElement: document.querySelector('.numbers'), // Элемент для отображения чисел
  numberStyleClass: 'number-style', // Класс стиля для чисел
};

if (!animationConfig.numbersElement) throw new Error('Элемент с классом .numbers не найден на странице.');

const state = {
  currentNumbers: '',
  count: 0,
  animate: null,
};

function getRandomBinaryNumber() {
  return Math.random() > 0.5 ? 1 : 0;
}

function generateRandomNumbers(count) {
  return Array.from({ length: count }, getRandomBinaryNumber).join('');
}

function animateNumbers() {
  let lastTime = 0;

  function update(timestamp) {
    if (!lastTime) lastTime = timestamp;
    const elapsed = timestamp - lastTime;

    if (elapsed >= animationConfig.numberChangeTime) {
      lastTime = timestamp;

      state.currentNumbers = state.currentNumbers
        .split('')
        .map(num => (Math.random() < animationConfig.percentageOfChanges ? getRandomBinaryNumber() : num))
        .join('');
      animationConfig.numbersElement.textContent = state.currentNumbers;
    }
    requestAnimationFrame(update); // Оставляем один вызов requestAnimationFrame в конце функции update
  }
  requestAnimationFrame(update); // Убран избыточный вызов
}

function getCharacterCount() {
  if (!charSizeCache) charSizeCache = getCharacterSize(); // Используем кешированный размер символа

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const cols = Math.floor(windowWidth / charSizeCache.width);
  const rows = Math.floor(windowHeight / charSizeCache.height);

  return cols * rows;
}

let charSizeCache = null; // Кэш размера символа
function getCharacterSize() {
  if (charSizeCache) return charSizeCache;

  const tempElement = document.createElement('span');
  tempElement.textContent = '0';
  tempElement.className = animationConfig.numberStyleClass;
  document.body.appendChild(tempElement);
  charSizeCache = { width: tempElement.offsetWidth, height: tempElement.offsetHeight };
  document.body.removeChild(tempElement);

  return charSizeCache;
}

function updateNumbers() {
  state.count = getCharacterCount();
  state.currentNumbers = generateRandomNumbers(state.count);
  animationConfig.numbersElement.textContent = state.currentNumbers;
}

function handleResize() {
  updateNumbers();
}

animationConfig.numbersElement.classList.add(animationConfig.numberStyleClass);
updateNumbers();
animateNumbers();
window.addEventListener('resize', handleResize);

//#2blue Функция для добавления класса "active" к активной ссылке
//#1blue ———————————————————————— для Локальной сборки
// function setActiveNavLink() {
//   // Получаем текущий путь страницы (например, '/about.html')
//   const currentPath = window.location.pathname;

//   // Получаем все ссылки в навигации
//   const navLinks = document.querySelectorAll('.navigation__a');

//   // Проходим по каждой ссылке и проверяем, соответствует ли ее путь текущему пути
//   navLinks.forEach(link => {
//     // Получаем элемент span с классом "navigation__span" внутри ссылки
//     const navigationSpan = link.querySelector('.navigation__span');

//     if (link.getAttribute('href') === currentPath) {
//       // Добавляем класс "active" к активной ссылке
//       link.classList.add('active');
//       // Добавляем класс "active" к элементу span внутри активной ссылки
//       navigationSpan.classList.add('active');
//     } else {
//       // Удаляем класс "active" у неактивных ссылок
//       link.classList.remove('active');
//       // Удаляем класс "active" у элемента span внутри неактивной ссылки
//       navigationSpan.classList.remove('active');
//     }
//   });
// }

// // Вызываем функцию при загрузке страницы
// document.addEventListener('DOMContentLoaded', () => {
//   setActiveNavLink();
// });

//#1blue ———————————————————————— для WordPress
function setActiveNavLink() {
  const currentPath = window.location.pathname;
  const navLinks = document.querySelectorAll('.navigation__a');

  navLinks.forEach(link => {
    const linkHref = link.getAttribute('href');
    const linkURL = new URL(linkHref); // Получаем путь без доменной части и параметров запроса.
    const linkPath = linkURL.pathname;

    // Удаляем слеш в конце текущего пути (если он есть)
    const sanitizedCurrentPath = currentPath.endsWith('/') ? currentPath.slice(0, -1) : currentPath;

    // Удаляем слеш в конце пути ссылки (если он есть)
    const sanitizedLinkPath = linkPath.endsWith('/') ? linkPath.slice(0, -1) : linkPath;

    const navigationSpan = link.querySelector('.navigation__span');

    if (sanitizedLinkPath === sanitizedCurrentPath) {
      link.classList.add('active');
      navigationSpan.classList.add('active');
    } else {
      link.classList.remove('active');
      navigationSpan.classList.remove('active');
    }
  });
}

// Вызываем функцию при загрузке страницы
document.addEventListener('DOMContentLoaded', () => {
  setActiveNavLink();
});

//#2blue Переключение темы на сайте
//#1blue ———————————————————————— для Локальной сборки
// import lightThemeImg from '../media/img/light_theme.svg';
// import darkThemeImg from '../media/img/dark_theme.svg';
//#1blue ———————————————————————— для WordPress
let lightThemeImg = custom_data.lightThemeImg;
let darkThemeImg = custom_data.darkThemeImg;
//#1blue ————————————————————————

const numbersDiv = document.querySelector('.numbers');
const themeIcon = document.getElementById('theme-icon');
const themeKey = 'selectedTheme';

// Проверяем и устанавливаем значение по умолчанию для selectedTheme
if (!localStorage.getItem(themeKey)) {
  localStorage.setItem(themeKey, 'dark');
}

// Функция для добавления класса и изменения иконки
function applyThemeClassAndIcon(theme) {
  if (theme === 'dark') {
    numbersDiv.classList.add('dark_theme');
    numbersDiv.classList.remove('light_theme');
    themeIcon.src = lightThemeImg;
  } else {
    numbersDiv.classList.add('light_theme');
    numbersDiv.classList.remove('dark_theme');
    themeIcon.src = darkThemeImg;
  }
}

// Функция для переключения темы
function toggleTheme() {
  const currentTheme = numbersDiv.classList.contains('dark_theme') ? 'dark' : 'light';
  const newTheme = currentTheme === 'dark' ? 'light' : 'dark';

  // Изменяем класс и иконку
  applyThemeClassAndIcon(newTheme);

  // Сохраняем выбранную тему в Local Storage
  localStorage.setItem(themeKey, newTheme);
}

// При загрузке страницы проверяем наличие выбранной темы в Local Storage
document.addEventListener('DOMContentLoaded', () => {
  const selectedTheme = localStorage.getItem(themeKey);
  if (selectedTheme === 'dark') {
    applyThemeClassAndIcon('dark');
  } else {
    applyThemeClassAndIcon('light');
  }
});

themeIcon.addEventListener('click', toggleTheme);

// #2blue Обработка событий для баннера который появляется после отправки пользователем сообщения в форме обратной связи
// Скрипт для добавления и удаления класса при активации/закрытии баннера
document.addEventListener('DOMContentLoaded', () => {
  const closeBannerButton = document.getElementById('close-banner');
  const bannerOverlay = document.getElementById('banner-overlay');

  if (closeBannerButton && bannerOverlay) {
    closeBannerButton.addEventListener('click', () => {
      closeBanner();
    });

    bannerOverlay.addEventListener('click', event => {
      if (event.target === bannerOverlay) {
        closeBanner();
      }
    });
  }

  function closeBanner() {
    bannerOverlay.style.display = 'none';
    document.body.classList.remove('banner-active');
  }

  // Активация класса при открытии баннера
  if (bannerOverlay) {
    bannerOverlay.style.display = 'flex'; // Показываем баннер по умолчанию
    document.body.classList.add('banner-active');
  }
});

// #2blue Кнопка выпадающего меню header мобильной версии
document.getElementById('toggleBtn').addEventListener('click', toggleMenu);

function toggleMenu() {
  const navigation = document.querySelector('.navigation');
  navigation.classList.toggle('show-menu');
}
